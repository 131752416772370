import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import ConceptDesignSlider from "../Elements/ConceptDesignSlider";
import ConceptDesignAbout3 from "../Elements/ConceptDesignAbout3";
import ConceptDesignAbout5 from "../Elements/ConceptAbout5";
import ConceptDesignService7 from "../Elements/ConceptDesignService7";
import ConceptDesignStatistics from "../Elements/ConceptDesignStatistics";
import ConceptDesignPlans from "../Elements/ConceptDesignPlans";

var bnrimg = require("./../../images/banner/4.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class ConceptDesign extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          {/* <Banner title="Service Details" pagename="Service Detail" description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." bgimage={bnrimg}/> */}
          {/* SECTION CONTENT START */}
          <ConceptDesignSlider />
          <ConceptDesignAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                    Why Choose Studio Sarva Architects for Concept Design?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Creative Visionaries</h4>
                      <p>
                      Our team of architects and designers are skilled in developing cutting-edge concepts that push the boundaries of design while remaining practical and aligned with your project’s needs. We balance innovation with functionality to ensure your space is both inspiring and operationally efficient.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Tailored to Your Vision
                      </h4>
                      <p>
                      Every project is unique, and we pride ourselves on creating concept designs that are tailored specifically to your business vision and goals. We take the time to understand your brand, ensuring the final design embodies your identity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Sustainability and Modern Trends
                      </h4>
                      <p>
                      Incorporating the latest trends and sustainable practices, our concept designs are forward-thinking, energy-efficient, and environmentally conscious. We believe in creating spaces that not only look great but also minimize their environmental impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Feasibility and Practicality</h4>
                      <p>
                      Our concept designs are not just visually appealing; they are backed by practical and technical expertise. We conduct feasibility studies to ensure that your vision can be realized within the given budget, timeline, and regulatory constraints.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConceptDesignAbout5 />
          <ConceptDesignService7 />
          <ConceptDesignStatistics />
          <ConceptDesignPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Footer />
      </>
    );
  }
}

export default ConceptDesign;

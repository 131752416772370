import React from 'react';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import WhatWeDo6 from './../Elements/WhatWeDo6';
import Services6 from './../Elements/Services6';
import Services7 from './../Elements/Services7';
import ClientsLogo2 from './../Elements/ClientsLogo2';

var bnrimg = require('./../../images/banner/service-banner.png');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner title="Service" pagename="Services" description="At Studio Sarva, we offer a range of architectural services specializing in residential, commercial, and hospitality projects. Our focus is on blending creativity and functionality to meet our clients' unique requirements, delivering exceptional results through quality craftsmanship and collaboration." bgimage={bnrimg}/>
                    {/* <WhatWeDo6 /> */}
                    <Services6 />
                    <Services7 />
                    {/* <ClientsLogo2 bgcolor="bg-gray" /> */}
                </div>
                <Footer />
            </>
        );
    };
};

export default Service1;
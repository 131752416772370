import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import CommercialSlider from "../Elements/CommercialSlider";
import CommercialAbout3 from "../Elements/CommercialAbout";
import CommercialAbout5 from "../Elements/CommercialAbout5";
import CommercialService7 from "../Elements/CommercialService7";
import CommercialStatistics from "../Elements/CommercialStatistics";
import CommercialPlans from "../Elements/CommercialPlans";

var bnrimg = require("./../../images/banner/4.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class CommercialConstruction extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          {/* <Banner title="Service Details" pagename="Service Detail" description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." bgimage={bnrimg}/> */}
          {/* SECTION CONTENT START */}
          <CommercialSlider />
          <CommercialAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                    Why Choose Studio Sarva Architects for Commercial Construction?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Tailored Solutions</h4>
                      <p>
                      We understand that every business has unique requirements. Our team works closely with you to create customized designs that align with your specific needs, ensuring the final space supports your business goals.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Commitment to Quality
                      </h4>
                      <p>
                      With a focus on high-quality craftsmanship, we ensure that all elements of the construction meet rigorous standards, delivering a finished product that enhances your business environment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Transparent Communication
                      </h4>
                      <p>
                      We maintain open lines of communication throughout the project, providing regular updates and addressing any questions or concerns promptly, so you're always informed about the progress.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Stress-Free Experience</h4>
                      <p>
                      By managing all complexities of the project, we allow you to concentrate on running your business while we take care of the details, ensuring a worry-free construction experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CommercialAbout5 />
          <CommercialService7 />
          <CommercialStatistics />
          <CommercialPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Footer />
      </>
    );
  }
}

export default CommercialConstruction;

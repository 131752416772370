import React from 'react';
import { NavLink } from 'react-router-dom';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import projectData from '../../assets/Jsondata/project.json'

const filters = [
    { label: "Architecture", filter: ".cat-1" },
    { label: "Interiors", filter: ".cat-4" },
    { label: "Residential", filter: ".cat-5" },
    { label: "Commercial", filter: ".cat-3" },
    { label: "Institutional", filter: ".cat-6" },
    { label: "Health Care", filter: ".cat-7" },
    { label: "Urban Development", filter: ".cat-7" },
    { label: "Hospitality", filter: ".cat-2" }
];

const projects = [
    {
        image: require('./../../images/projects/guptaresidency/gupta.png'),
        title: 'Gupta Residency',
        slug: 'gupta-residency',
        description: 'We combine Interior and Exterior Design services and often provide...',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/jaswanth/jaswanth.png'),
        title: 'Jaswanth Residency',
        slug: 'jaswanth-residency',
        description: 'Landscape plans for drainage problems may also entail planting.',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/koushik/koushik.png'),
        title: 'Koushik Residency',
        slug: 'koushik-residency',
        description: 'We provide a range of architectural 3D modeling services to our customers...',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/nagesh/nagesh.png'),
        title: 'Nagesh Residency',
        slug: 'nagesh-residency',
        description: 'Landscape plans for drainage problems may also entail planting beds away ...',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/shruthi/shruthi.png'),
        title: 'Shruthi Residency',
        slug: 'shruthi-residency',
        description: 'Project management is the process by which our team plans and executes...',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/aerospace/aerospace.png'),
        title: 'TM Aerospace',
        slug: 'tm-aerospace',
        description: 'Our team also provides consultations on all architectural issues, even if you need...',
        filter: 'cat-3'
    },
    // {
    //     image: require('./../../images/projects/portrait/pic9.jpg'),
    //     title: 'Living Room',
    //     slug: 'slug7',
    //     description: 'Landscape plans for drainage problems may also entail planting beds...',
    //     filter: 'cat-3'
    // },
    // {
    //     image: require('./../../images/projects/square/pic2.jpg'),
    //     title: 'Wall Interior',
    //     slug: 'slug8',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-2'
    // },
    // {
    //     image: require('./../../images/projects/portrait/pic2.jpg'),
    //     title: 'Dream Home',
    //     slug: 'slug9',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-3'
    // },

    // {
    //     image: require('./../../images/projects/portrait/pic3.jpg'),
    //     title: 'Queens Museum',
    //     slug: 'slug10',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-3'
    // },
    // {
    //     image: require('./../../images/projects/square/pic1.jpg'),
    //     title: 'Life style building',
    //     slug: 'slug11',
    //     description: 'Project management is the process by which our team plans and executes...',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/projects/square/pic3.jpg'),
    //     title: 'Hotel Shears',
    //     slug: 'slug12',
    //     description: 'Landscape plans for drainage problems may also entail planting beds...',
    //     filter: 'cat-2'
    // },
    // {
    //     image: require('./../../images/projects/portrait/pic4.jpg'),
    //     title: 'Modern Bathroom',
    //     slug: 'slug13',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/projects/square/pic9.jpg'),
    //     title: 'Dream House',
    //     slug: 'slug14',
    //     description: 'Landscape plans for drainage problems may also entail planting beds...',
    //     filter: 'cat-2'
    // },
    // {
    //     image: require('./../../images/projects/square/pic10.jpg'),
    //     title: 'Life style building',
    //     slug: 'slug14',
    //     description: 'Project management is the process by which our team plans and executes...',
    //     filter: 'cat-3'
    // }
]


var bnrimg = require('./../../images/banner/project-banner.png');
var bgimg1 = require('./../../images/background/cross-line.png');

class ProjectMasonary3 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner title="All Projects" pagename="Our Works" description="Explore Studio Sarva's portfolio to see our innovative architectural designs in residential, commercial, and hospitality projects. Our work showcases the perfect blend of creativity and functionality, reflecting our commitment to quality and client satisfaction." bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-white inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="container">
                            {/* Filter Nav START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation masonry-filter clearfix">
                                    <li className="active"><NavLink to={"#"} className="btn from-top" data-filter="*" data-hover="All">All</NavLink></li>
                                    {filters.map((item, index) => (
                                        <li key={index}><NavLink to={"#"} className="btn from-top" data-filter={item.filter} >{item.label}</NavLink></li>
                                    ))}
                                </ul>
                            </div>
                            {/* Filter Nav END */}
                            {/* GALLERY CONTENT START */}
                            <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-lg-4  col-md-4 col-sm-6 m-b30`}>
                                        <div className="project-mas hover-shadow">
                                            <div className="image-effect-one">
                                                <img src={item.image} alt="" />
                                                <div className="figcaption">
                                                    <a className="mfp-link" href={item.image}>
                                                        <i className="fa fa-arrows-alt" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="project-info p-a20 bg-gray">
                                                <h4 className="sx-tilte m-tb0"><NavLink to={`/project-detail2/${item.slug}`}>{item.title}</NavLink></h4>
                                                <p>{item.description}</p>
                                                <NavLink to={"#"}><i className="link-plus bg-primary" /></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                            {/* GALLERY CONTENT END */}
                            {/* <div className="text-center load-more-btn-outer" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                <button className="site-button-secondry btn-half"><span>Load More</span></button>
                            </div> */}
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectMasonary3;
// import React from 'react';
// import Header4 from './../Common/Header4';
// import Footer from './../Common/Footer';
// import Banner from './../Elements/Banner';
// import GoogleMapReact from 'google-map-react';

// var bnrimg = require('./../../images/banner/contact-banner.png');

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// class ContactUs extends React.Component {
//     render() {
//         const defaultProps = {
//             center: {
//               lat: 34.073280,
//               lng: -118.251410
//             },
//             zoom: 12
//         };
//         return (
//             <>
//                 <Header4 />
//                 <div className="page-content">
//                     <Banner title="Contact Us" pagename="Contact us" description="Contact Studio Sarva to discuss your architectural needs. We specialize in residential, commercial, and hospitality projects, blending creativity with functionality to deliver exceptional results. Reach out to us, and let’s turn your vision into reality." bgimage={bnrimg}/>
//                     {/* SECTION CONTENTG START */}
//                     <div className="section-full p-tb80 inner-page-padding">
//                         {/* LOCATION BLOCK*/}
//                         <div className="container">
//                             {/* GOOGLE MAP & CONTACT FORM */}
//                             <div className="section-content">
//                                 {/* CONTACT FORM*/}
//                                 <div className="row">
//                                     <div className="col-lg-8 col-md-12 col-sm-12">
//                                         <form className="contact-form cons-contact-form bg-gray p-a30" method="post" action="http://localhost/sarva">
//                                             <div className="contact-one">
//                                                 {/* TITLE START */}
//                                                 <div className="section-head">
//                                                     <div className="sx-separator-outer separator-left">
//                                                         <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
//                                                             <h3 className="sep-line-one">Form</h3>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 {/* TITLE END */}
//                                                 <div className="form-group">
//                                                     <input name="username" type="text" required className="form-control" placeholder="Name" />
//                                                 </div>
//                                                 <div className="form-group">
//                                                     <input name="email" type="text" className="form-control" required placeholder="Email" />
//                                                 </div>
//                                                 <div className="form-group">
//                                                     <input name="phonenumber" type="tel" className="form-control" required placeholder="Phone Number" />
//                                                 </div>
//                                                 <div className="form-group">
//                                                     {/* <input name="phonenumber" type="tel" className="form-control" required placeholder="Phone Number" /> */}
//                                                     <select placeholder='Select the Service' name="service" className='form-control' required>
//                                                         <option value="" selected>Select the Service</option>
//                                                         <option value="Architectural Consultancy">Architectural Consultancy</option>
//                                                         <option value="Turnkey Solutions">Turnkey Solutions</option>
//                                                         <option value="Interior Design">Interior Design</option>
//                                                         <option value="Urban Design & Development">Urban Design & Development</option>
//                                                     </select>
//                                                 </div>
//                                                 <div className="form-group">
//                                                     <textarea name="message" rows={4} className="form-control " required placeholder="Message" defaultValue={""} />
//                                                 </div>
//                                                 <div className="text-right">
//                                                     <button name="submit" type="submit" value="Submit" className="site-button btn-half"><span> submit</span>
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                     <div className="col-lg-4 col-md-12 col-sm-12">
//                                         <div className="contact-info block-shadow bg-white bg-center p-a40" style={{ backgroundImage: 'url(images/background/bg-map.png)' }}>
//                                             <div>
//                                                 {/* TITLE START */}
//                                                 <div className="section-head">
//                                                     <div className="sx-separator-outer separator-left">
//                                                         <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
//                                                             <h3 className="sep-line-one">Info</h3>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 {/* TITLE END */}
//                                                 <div className="sx-icon-box-wraper left p-b30">
//                                                     <div className="icon-xs"><i className="fa fa-phone" /></div>
//                                                     <div className="icon-content">
//                                                         <h5 className="m-t0">Phone number</h5>
//                                                         <p>+917760949449</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="sx-icon-box-wraper left p-b30">
//                                                     <div className="icon-xs"><i className="fa fa-envelope" /></div>
//                                                     <div className="icon-content">
//                                                         <h5 className="m-t0">Email address</h5>
//                                                         <p>info@studiosarva.com</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="sx-icon-box-wraper left">
//                                                     <div className="icon-xs"><i className="fa fa-map-marker" /></div>
//                                                     <div className="icon-content">
//                                                         <h5 className="m-t0">Address info</h5>
//                                                         <p>Kempegowda MN Road, Dasarahalli Main Rd, opposite Amruth Maruthi Temple, Hebbal Kempapura, Bengaluru, Karnataka 560024</p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="gmap-outline">
//                         {/* <GoogleMapReact
//                             bootstrapURLKeys={{ key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0" }}
//                             defaultCenter={defaultProps.center}
//                             defaultZoom={defaultProps.zoom}
//                             >
//                             <AnyReactComponent lat={34.073280} lng={-118.251410} text={<i className="fa fa-map-marker" />}                                        />
//                         </GoogleMapReact>                         */}
//                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124405.88122359203!2d77.4969771896266!3d12.992065519316622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1763f3eab0e3%3A0x491f705156d60ef4!2sStudio%20Sarva!5e0!3m2!1sen!2sin!4v1728381204273!5m2!1sen!2sin" width="1600" height="650"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//                     </div>
//                     {/* SECTION CONTENT END */}
//                 </div>

//                 <Footer />
//             </>
//         );
//     };
// };

// export default ContactUs;

import React, { useState } from 'react';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

const bnrimg = require('./../../images/banner/contact-banner.png');

const ContactUs = () => {

    const [formData, setFormData] = useState({
        username: "",
        email: "",
        service: "",
        phonenumber: "",
        message: "",
      });

      console.log("formdata in contact", formData)
    
      const [errors, setErrors] = useState({});
      const [showModal, setShowModal] = useState(false);
      const [modalMessage, setModalMessage] = useState("");
      const [isLoading, setIsLoading] = useState(false);
    
      const handleChange = (e) => {
        if (e && e.target) {
          const { name, value, type, checked } = e.target;
          setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
          }));
        } else {
          // Assume this is the value from the Select component
          setFormData((prevData) => ({
            ...prevData,
            model: e, // 'e' is the selected model value
          }));
        }
      };
    
    
      const validateForm = () => {
        const errors = {};
        if (!formData.username.trim()) errors.username = "Name is required";
        if (!formData.email.trim()) {
          errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = "Email is invalid";
        }
        if (!formData.service.trim()) errors.service = "Service is required";
        if (!formData.phonenumber.trim()) {
          errors.phonenumber = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phonenumber)) {
          errors.phonenumber = "Phone number is invalid";
        }
        return errors;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formErrors = validateForm();
    
        if (Object.keys(formErrors).length === 0) {
          try {
            const response = await fetch("https://studiosarva.com/form/contact.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            });
    
            const data = await response.json();
    
            if (response.ok) {
              if (data.status === "success") {
                window.location.href = "https://studiosarva.com/form/thankyou.html";
              } else {
                console.error("Error:", data.message);
                setModalMessage(data.message || "An error occurred. Please try again.");
              }
            } else {
              console.error("Error:", data.message);
              setModalMessage(data.message || "An error occurred. Please try again.");
            }
          } catch (error) {
            console.error("Error submitting form:", error);
            setModalMessage("An error occurred. Please try again later.");
          }
        } else {
            console.log("first error")
          setErrors(formErrors);
          setModalMessage("Please correct the errors in the form.");
        }
    
        setIsLoading(false);
        setShowModal(true);
      };

    const defaultProps = {
        center: {
            lat: 34.073280,
            lng: -118.251410
        },
        zoom: 12
    };

    return (
        <>
            <Header4 />
            <div className="page-content">
                <Banner
                    title="Contact Us"
                    pagename="Contact us"
                    description="Contact Studio Sarva to discuss your architectural needs. We specialize in residential, commercial, and hospitality projects, blending creativity with functionality to deliver exceptional results. Reach out to us, and let’s turn your vision into reality."
                    bgimage={bnrimg}
                />
                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="section-content">
                            {/* CONTACT FORM */}
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <form
                                        className="contact-form bg-gray p-a30"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="contact-one">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="sx-separator-outer separator-left">
                                                    <div
                                                        className="sx-separator bg-white bg-moving bg-repeat-x"
                                                        style={{
                                                            backgroundImage: 'url(images/background/cross-line2.png)'
                                                        }}
                                                    >
                                                        <h3 className="sep-line-one">Form</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="form-group">
                                                <input
                                                    name="username"
                                                    onChange={handleChange}
                                                    type="text"
                                                    required
                                                    className="form-control"
                                                    placeholder="Name"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    name="email"
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Email"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    name="phonenumber"
                                                    onChange={handleChange}
                                                    type="tel"
                                                    className="form-control"
                                                    required
                                                    placeholder="Phone Number"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    placeholder="Select the Service"
                                                    name="service"
                                                    className="form-control"
                                                    onChange={(value) => handleChange(value)}
                                                    required
                                                >
                                                    <option value="" defaultValue>
                                                        Select the Service
                                                    </option>
                                                    <option value="Architectural Consultancy">
                                                        Architectural Consultancy
                                                    </option>
                                                    <option value="Turnkey Solutions">Turnkey Solutions</option>
                                                    <option value="Interior Design">Interior Design</option>
                                                    <option value="Urban Design & Development">
                                                        Urban Design & Development
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <textarea
                                                    name="message"
                                                    rows={4}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    required
                                                    placeholder="Message"
                                                />
                                            </div>
                                            <div className="text-right">
                                                <button
                                                    type="submit"
                                                    // value="Submit"
                                                    disabled={isLoading}
                                                    className="site-button btn-half"
                                                >
                                                    <span>{isLoading ? "Submitting..." : "Submit"}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div
                                        className="contact-info block-shadow bg-white bg-center p-a40"
                                        style={{
                                            backgroundImage: 'url(images/background/bg-map.png)'
                                        }}
                                    >
                                        <div>
                                            <div className="section-head">
                                                <div className="sx-separator-outer separator-left">
                                                    <div
                                                        className="sx-separator bg-white bg-moving bg-repeat-x"
                                                        style={{
                                                            backgroundImage: 'url(images/background/cross-line2.png)'
                                                        }}
                                                    >
                                                        <h3 className="sep-line-one">Info</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sx-icon-box-wraper left p-b30">
                                                <div className="icon-xs">
                                                    <i className="fa fa-phone" />
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0">Phone number</h5>
                                                    <p>+917760949449</p>
                                                </div>
                                            </div>
                                            <div className="sx-icon-box-wraper left p-b30">
                                                <div className="icon-xs">
                                                    <i className="fa fa-envelope" />
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0">Email address</h5>
                                                    <p>info@studiosarva.com</p>
                                                </div>
                                            </div>
                                            <div className="sx-icon-box-wraper left">
                                                <div className="icon-xs">
                                                    <i className="fa fa-map-marker" />
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0">Address info</h5>
                                                    <p>
                                                        Kempegowda MN Road, Dasarahalli Main Rd, opposite Amruth Maruthi
                                                        Temple, Hebbal Kempapura, Bengaluru, Karnataka 560024
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gmap-outline">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124405.88122359203!2d77.4969771896266!3d12.992065519316622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1763f3eab0e3%3A0x491f705156d60ef4!2sStudio%20Sarva!5e0!3m2!1sen!2sin!4v1728381204273!5m2!1sen!2sin"
                        width="1600"
                        height="650"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
                {/* SECTION CONTENT END */}
            </div>

            <Footer />
        </>
    );
};

export default ContactUs;

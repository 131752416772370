import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const projects = [
    {
        image: require('./../../images/projects/guptaresidency/1.jpg'),
        title: 'Gupta Residency',
        slug: 'gupta-residency',
        description: 'We combine Interior and Exterior Design services and often provide...',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/jaswanth/1.jpg'),
        title: 'Jaswanth Residency',
        slug: 'jaswanth-residency',
        description: 'Landscape plans for drainage problems may also entail planting.',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/koushik/1.jpg'),
        title: 'Koushik Residency',
        slug: 'koushik-residency',
        description: 'We provide a range of architectural 3D modeling services to our customers...',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/nagesh/1.jpg'),
        title: 'Nagesh Residency',
        slug: 'nagesh-residency',
        description: 'Landscape plans for drainage problems may also entail planting beds away ...',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/shruthi/15.jpg'),
        title: 'Shruthi Residency',
        slug: 'shruthi-residency',
        description: 'Project management is the process by which our team plans and executes...',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/aerospace/1.jpg'),
        title: 'TM Aerospace',
        slug: 'tm-aerospace',
        description: 'Our team also provides consultations on all architectural issues, even if you need...',
        filter: 'cat-3'
    },
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class Projects7 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: false,
            center: false,
            items: 3,
            margin: 40,
            nav: true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                    margin: 15,
                },
                640: {
                    items: 2,
                    margin: 15
                },
                768: {
                    items: 2,
                    margin: 15
                },
                991: {
                    items: 3,
                    margin: 15
                },
                1200: {
                    items: 3
                }

            }
        };
        return (
            <>
                <div className="section-full p-tb80 bg-gray inner-page-padding">
                    <div className="container-fluid">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                    <h3 className="sep-line-one">Similar Project</h3>
                                </div>
                            </div>
                        </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                            <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center p-lr80" {...options}>
                                    {projects.map((item, index) => (
                                        <div key={index} className="item">
                                        <div className="project-mas m-a30">
                                            <div className="image-effect-one">
                                                <img src={item.image} alt=""/>
                                                <div className="figcaption">
                                                    <a className="mfp-link" href={item.image}>
                                                        <i className="fa fa-arrows-alt" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="project-info p-a20 bg-white">
                                                <h4 className="sx-tilte m-t0"><NavLink to={`/project-detail2/${item.slug}`}>{item.title}</NavLink></h4>
                                                <p>{item.description}</p>
                                                <NavLink to={"#"}><i className="link-plus bg-primary" /></NavLink>
                                            </div>
                                        </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
};

export default Projects7;
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

const blogs = [
    {
        image: require('./../../images/blog/portrait/1.png'),
        title: 'We’ll nail your next project, because nobody wants...',
        author: 'John',
        date: '5',
        month: 'SEP',
        comments: '5 Comment'
    },
    {
        image: require('./../../images/blog/blog-grid/1.png'),
        title: 'Helping you and your house become better acquainted.',
        author: 'John',
        date: '25',
        month: 'SEP',
        comments: '5 Comment'
    },
    {
        image: require('./../../images/blog/portrait/2.png'),
        title: 'Creating quality urban lifestyles, building...',
        author: 'John',
        date: '26',
        month: 'SEP',
        comments: '5 Comment'
    },
    {
        image: require('./../../images/blog/blog-grid/2.png'),
        title: 'Don’t get framed by the competition, trust our...',
        author: 'John',
        date: '16',
        month: 'SEP',
        comments: '3 Comment'
    },
    {
        image: require('./../../images/blog/portrait/3.png'),
        title: 'When it comes to your house, don’t mess...',
        author: 'John',
        date: '18',
        month: 'SEP',
        comments: '5 Comment'
    },
    {
        image: require('./../../images/blog/blog-grid/3.png'),
        title: 'We’re the construction kings, building up great...',
        author: 'John',
        date: '15',
        month: 'SEP',
        comments: '2 Comment'
    }
]

var bnrimg = require('./../../images/banner/blog-banner.png');

class BlogMasonary extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner title="Blogs" pagename="Blogs" description="Discover insights and inspiration on Studio Sarva's blog. Explore topics on architectural design, residential, commercial, and hospitality projects, where creativity meets functionality. Stay updated with the latest trends and ideas in the industry." bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="container clearfix">
                            <div className="masonry-outer mfp-gallery news-masonry  row">
                            {blogs.map((item, index) => (
                                    <div className="masonry-item  col-lg-4 col-md-6 col-sm-12" key={index}>
                                    <div className="blog-post blog-grid date-style-2">
                                        <div className="sx-post-media sx-img-effect img-reflection">
                                            <NavLink to={""}><img src={item.image} alt="" /></NavLink>
                                        </div>
                                        <div className="sx-post-info p-t30">
                                            <div className="sx-post-meta ">
                                                <ul>
                                                    <li className="post-date"><strong>{item.date}</strong> <span>{item.month}</span> </li>
                                                    <li className="post-author"><NavLink to={""}>By <span>{item.author}</span></NavLink> </li>
                                                    <li className="post-comment"> <NavLink to={""}>{item.comments}</NavLink> </li>
                                                </ul>
                                            </div>
                                            <div className="sx-post-title ">
                                                <h4 className="post-title"><NavLink to={""}>{item.title}</NavLink></h4>
                                            </div>
                                            <div className="sx-post-readmore">
                                                <NavLink to={""} title="READ MORE" rel="bookmark" className="site-button-link">View More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                            </div>
                            {/* <ul className="pagination m-t30 m-b0">
                                <li><NavLink to={"#"}>«</NavLink></li>
                                <li className="active"><NavLink to={"#"}>1</NavLink></li>
                                <li><NavLink to={"#"}>2</NavLink></li>
                                <li><NavLink to={"#"}>3</NavLink></li>
                                <li><NavLink to={"#"}>»</NavLink></li>
                            </ul> */}
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default BlogMasonary;
import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import ArchConsultancySlider from "../Elements/ArchConsultancySlider";
import ArchConsultancyAbout3 from "../Elements/ArchConsultancyAbout3";
import ArchConsultancyAbout5 from "../Elements/ArchConsultancyAbout5";
import ArchConsultancyService7 from "../Elements/ArchConsultancyService7";
import ArchConsultancyStatistics from "../Elements/ArchConsultancyStatistics";
import ArchConsultancyPlans from "../Elements/ArchConsultancyPlans";
import Banner from "../Elements/Banner";

var bnrimg = require("./../../images/architectural1.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class ArchitecturalConsultancy extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner title="Architectural Consultancy" pagename="Architectural Consultancy" description="Our architectural consultancy offers expert guidance from concept development to construction, ensuring innovative, sustainable, and aesthetically pleasing designs that meet your vision..." bgimage={bnrimg}/>
          {/* SECTION CONTENT START */}
          {/* <ArchConsultancySlider /> */}
          <ArchConsultancyAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                      Why Choose Studio Sarva Architects for Architectural
                      Consultancy?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Tailored Solutions</h4>
                      <p>
                        Our consultancy services are customized to address the
                        specific requirements of each project, ensuring that we
                        provide relevant, effective solutions that align with
                        your vision and objectives.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Expert Advice</h4>
                      <p>
                        With years of experience, our team provides expert
                        guidance on design, material selection, regulatory
                        compliance, and project planning, ensuring every detail
                        is carefully considered.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                        Sustainable and Innovative Practices
                      </h4>
                      <p>
                        We incorporate sustainable architectural solutions into
                        our consultancy, prioritizing energy efficiency,
                        environmental impact, and modern design techniques to
                        create functional and future-ready spaces.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                        Seamless Project Coordination
                      </h4>
                      <p>
                        From design to execution, our consultancy services
                        ensure seamless project coordination, making sure that
                        all teams involved in the project work in harmony,
                        resulting in a well-executed design.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ArchConsultancyAbout5 />
          <ArchConsultancyService7 />
          <ArchConsultancyStatistics />
          <ArchConsultancyPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Footer />
      </>
    );
  }
}

export default ArchitecturalConsultancy;
